<div class="zoom-warning">
  <button (click)="closeOverlay()">
    <mat-icon>close</mat-icon>
  </button>
  <p>{{ translatedText.zoom_warning }}</p>
  <p class="zoom-article-link">
    <a
      href="https://support.laletu.de/hc/de/articles/16091075835164-So-beheben-Sie-Darstellungsprobleme-durch-Zoom-Einstellungen"
      target="_blank"
      >{{ translatedText.zoom_warning_more }}</a
    >
  </p>
</div>
