import {
  Component,
  Renderer2,
  ElementRef,
  OnInit,
  ViewChild,
  OnChanges,
  SimpleChanges,
  HostListener,
} from '@angular/core';
import { AuthGuard } from './auth/auth.guard';
import { AuthService } from './auth/auth.service';
import { Platform } from '@ionic/angular';
import { Router, NavigationStart, GuardsCheckEnd } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { CapacitorService } from './providers/capacitor.service';
import { StudentService } from './providers/student.service';
import { TeacherService } from './providers/teacher.service';
import { LicenseService } from './providers/license.service';
import Userback from '@userback/widget';
import { environment } from 'src/environments/environment';
import { SplashScreen } from '@capacitor/splash-screen';
import { NotificationService } from './providers/notification.service';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { NotificationListComponent } from './helpers/notification-list/notification-list.component';
import { ChangePasswordComponent } from './user/change-password/change-password.component';
import { PinCodeComponent } from './helpers/pin-code/pin-code.component';
import { BookPreferencesComponent } from './books/book-preferences/book-preferences.component';
import { MicrophoneCheckComponent } from './microphone-check/microphone-check.component';
import moment from 'moment';
import { ReadingLevelOverlayComponent } from './helpers/reading-level-overlay/reading-level-overlay.component';
import { GoogleAnalyticsService } from './providers/googleAnalytics.service';
import { LoginDialogComponent } from './static/home/login-dialog/login-dialog.component';
import { SubscribeDialogComponent } from './static/home/subscribe-dialog/subscribe-dialog.component';
import { TranslationService } from './providers/translation.service';
import { DiagnosticCreateComponent } from './diagnostic/diagnostic-create/diagnostic-create.component';
import { TrainingTaskCreateComponent } from './training-task/training-task-create/training-task-create.component';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ExpertGroupComponent } from './helpers/expert-group/expert-group.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorSnackbarComponent } from './helpers/snackbar/error-snackbar/error-snackbar.component';
import { SessionUploadOverlayComponent } from './helpers/session-upload-overlay/session-upload-overlay.component';
import { ReadingJsonService } from './providers/readingJson.service';
import { LanguageChangeAlertComponent } from './helpers/language-change-alert/language-change-alert.component';
import { TrialBannersComponent } from './helpers/trial-banners/trial-banners.component';
import { NewLicenseOverlayComponent } from './helpers/new-license-overlay/new-license-overlay.component';
import { NgControl } from '@angular/forms';
import { ZoomWarningComponent } from './helpers/zoom-waring/zoom-warning.component';

declare const Cookiebot: any;
export interface Notification {
  notificationId?: string;
  message?: string;
  recipient?: any;
  _task?: any;
  type?: string;
  readAt?: any;
  createdAt?: any;
  sender?: any;
}
export interface Student {
  username?: string;
  avatarUrl?: string;
  level?: number;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  environment = environment;
  APP_VERSION = 'v' + environment.appVersion;
  HOTFIX_VERSION = environment.hotfixVersion;
  IS_APP = environment.isApp;
  externalAssetsUrl = environment.externalAssets;
  register_from: string = environment.whitelabel.register_form; // no-school-swiss

  hideHotfixVersion: boolean = true;
  languages: any[] = [];
  hotfixCount = 0;
  hotfixClickDate: any = new Date().getTime();
  userIsAuthenticated: boolean = false;
  isImpersonationMode: boolean = false;
  diagnosticLicenseStatusActive: boolean = false;
  trainingLicenseStatusActive: boolean = false;
  isMobile: boolean = false;
  deviceInfo;
  hideNav: boolean = false;
  userType: string;
  id;
  uuid;
  student: Student;
  teacherLastname;
  teacherSalutation;
  teacherInitials;
  teacherisExpert: boolean = false;
  impersonationName: string = '';
  langAlertOnce: number = 0;
  userback;
  IS_TOUCH_DEVICE: boolean = false; // TODO: Does not get used?
  previousUrl: string;
  collapsedSidebar: boolean = false;
  mobileOverlay: boolean = false;
  hoverClass: string = 'register-section';
  isHomeButton: boolean = false;
  clickedMobileMenu: boolean = false;
  notificationBadgeCount;
  starBadgeLevel: string = '0';
  notifications: Notification[] = [];
  userId;
  colClass: string = 'col-md-9';
  isSidebar: boolean = true;
  studentReportValue: string;
  translatedText;
  isExpert: boolean = false;
  trialStatus: { isActive: boolean; expiryDate: Date } = {
    isActive: false,
    expiryDate: null,
  };
  showTrialBanner: boolean = false; // to avoid multiple trial banners
  devicePlatform: string;
  private _unsubscribeAll: Subject<any>;

  constructor(
    private authGuard: AuthGuard,
    private router: Router,
    private platform: Platform,
    private authService: AuthService,
    private capacitorService: CapacitorService,
    private studentService: StudentService,
    private teacherService: TeacherService,
    private licenseService: LicenseService,
    private readingJsonService: ReadingJsonService,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    public snackBar: MatSnackBar,
    private notificationService: NotificationService,
    public dialog: MatDialog,
    private readonly googleAnalyticsService: GoogleAnalyticsService,
    private translationService: TranslationService,
    public breakpointObserver: BreakpointObserver,
  ) {
    this.initializeApp();

    // initialize cookiebot
    if (!this.IS_APP) {
      this.googleAnalyticsService.initializeCookieBot();
    }

    //this.initializeGoogleAnalytics();

    // Add body class based on route
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        // Reset hover class on each route change
        this.hoverClass = 'register-section';
        if (this.previousUrl) {
          this.renderer.removeClass(document.body, this.previousUrl);
        }
        const currentUrlSlug = event.url.split('/')[1];
        // console.log(currentUrlSlug)
        let bodyClass = 'route--home';
        if (currentUrlSlug) {
          bodyClass = 'route--' + currentUrlSlug;
        }
        this.renderer.addClass(document.body, bodyClass);
        this.previousUrl = bodyClass;

        if (this.userType == 'student') {
          this.studentService.getStudentbyId(this.id).subscribe((res) => {
            if (res['levelHistory'].length > 1) {
              if (res['report'].includes('level')) {
                const latestLevelProgress = this.findLatestLevelProgress(
                  res['levelHistory'],
                );
                if (latestLevelProgress) {
                  this.studentReportValue = 'level';
                  this.openReadingLevel(
                    latestLevelProgress.level,
                    latestLevelProgress.progress,
                  );
                }
              }
            } else if (res['levelHistory'].length == 1) {
              this.updateStudent();
            }
          });
          // Check for broken session uploads
          this.readingJsonService
            .getAllSessionsKeysFromLocalDatabase()
            .then((keys: number[]) => {
              if (keys.length > 0) {
                this.openSessionUploadOverlay(keys);
              } else {
                console.log('No entries found in local database.');
              }
            })
            .catch((error: any) => {
              console.error('Error getting keys:', error);
            });
        }
      }
      if (event instanceof GuardsCheckEnd) {
        const currentUrlSlug = event.url.split('/')[1];
        // console.log(currentUrlSlug)
        if (currentUrlSlug == 'home' || currentUrlSlug == '') {
          this.isHomeButton = true;
        } else {
          this.isHomeButton = false;
        }
        if (
          currentUrlSlug == 'student-report' ||
          currentUrlSlug == 'release-notes'
        ) {
          this.isSidebar = false;
        } else {
          this.isSidebar = true;
        }
      }
    });

    // Check for touch device
    this.IS_TOUCH_DEVICE = this.authGuard.isTouchDevice();
    if (this.IS_TOUCH_DEVICE) {
      //document.documentElement.classList.add('touch_detected');
    }
    // Capacitor device info
    this.capacitorService.readDeviceInfo().then(
      (data) => {
        this.deviceInfo = data;
        console.log(data);
        this.devicePlatform = this.deviceInfo.platform.toLowerCase();

        // Set device data for other components
        // if(this.IS_APP) {
        //   document.documentElement.classList.add('is_app');
        //   // Check for maintenance mode and app update
        //   this.updateService.checkForMaintenanceAndUpdatesListener();
        // } else {
        //   // Check for maintenance mode and app update
        //   this.updateService.checkForMaintenanceAndUpdates();
        // }
        document.documentElement.classList.add(
          'platform-' + this.devicePlatform,
        );
      },
      (err) => {
        console.log(err);
      },
    );
    this._unsubscribeAll = new Subject();

    // this.router.events.subscribe((event: Event) => {
    //   if (event instanceof NavigationEnd) {
    //       // Hide loading indicator
    //       let currentLocation = event.urlAfterRedirects;
    //       if (
    //           currentLocation.includes('/home') ||
    //           currentLocation.includes('/ueber-uns')||
    //           currentLocation.includes('/nutzungsbedingungen')||
    //           currentLocation.includes('/datenschutz')||
    //           currentLocation.includes('/kontakt') ||
    //           currentLocation.includes('/impressum')
    //         ) {
    //           this.hideNav = false;
    //       } else if (
    //           currentLocation.includes('/login') ||
    //           currentLocation.includes('/registrierung') ||
    //           currentLocation.includes('/create-password') ||
    //           currentLocation.includes('/reset-password') ||
    //           currentLocation.includes('/forgot-password')
    //         ) {
    //         this.hideNav = true;
    //       }
    //   }
    // });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      SplashScreen.hide();
    });

    if (environment.isApp) {
      this.capacitorService.appUrlOpenListener();
    }
  }

  async initUserback() {
    // Add Userback feedback widget with custom data
    if (environment.userbackActive) {
      this.userback = await Userback(environment.userbackAccessToken, {
        widget_settings: {
          language: 'de',
          style: 'text',
          position: 'se',
        },
        // custom_data: this.userIsAuthenticated ? {
        //   userRole: this.userType,
        //   userId: this.id,
        //   userUuid: this.uuid
        // } : {}
      });
    }
  }

  openExpertGroupOverlay() {
    let dialogRef = this.dialog.open(ExpertGroupComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'expert-group-overlay-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'reading-level-overlay-panel_backdrop',
      data: {},
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((res) => {
      this.teacherService.readSingleTeacher(this.id).subscribe((res) => {
        if (res['isExpert'] == false || res['isExpert'] == undefined) {
          this.isExpert = false;
        } else {
          this.isExpert = true;
        }
        this.removeTeacherReportInfo({
          teacherId: this.id,
          info: 'expert-info',
        });
      });
    });
  }

  openNewLicenseModelOverlay() {
    let dialogRef = this.dialog.open(NewLicenseOverlayComponent, {
      width: '100%',
      autoFocus: false,
      disableClose: true,
      panelClass: 'trial-banner-panel',
      backdropClass: 'license-transfer-backdrop',
      data: {},
    });
    dialogRef.afterClosed().subscribe((res) => {
      this.teacherService.readSingleTeacher(this.id).subscribe(() => {
        if (res == 'license-mapping-info') {
          this.removeTeacherReportInfo({
            teacherId: this.id,
            info: 'license-mapping-info',
          });
        }
      });
    });
  }

  openSessionUploadOverlay(keys) {
    let dialogRef = this.dialog.open(SessionUploadOverlayComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'session-upload-overlay-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'session-upload-overlay-panel_backdrop',
      data: {
        keys: keys,
      },
    });
    // dialogRef.disableClose = true;
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {});
  }

  createTaskDialog(): void {
    let dialogRef = this.dialog.open(TrainingTaskCreateComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'create-training-task-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'create-training-task-panel-backdrop',
      //animation: { to: 'left' },
      //position: { rowEnd: '10%' },
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((res) => {});
  }

  createDiagnoseDialog() {
    let dialogRef = this.dialog.open(DiagnosticCreateComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'create-training-task-panel',
      disableClose: true,
      backdropClass: 'create-training-task-panel-backdrop',
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((res) => {});
  }

  ngOnInit(): void {
    this.translationService
      .getTranslation()
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
        this.languages = [
          {
            value: 'de_de',
            viewValue: this.translatedText?.de,
          },
          {
            value: 'en',
            viewValue: this.translatedText?.en,
          },
        ];
      });

    // Impersonator listener
    this.authService
      .getImpersonationStatusListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((isImpersonated) => {
        console.log(isImpersonated);
        if (isImpersonated) {
          setTimeout(() => {
            this.impersonationName = this.authService.getImpersonatedName();
            this.renderer.addClass(
              document.documentElement,
              'impersonator-mode',
            );
            this.isImpersonationMode = true;
          }, 500);
        } else {
          this.impersonationName = '';
          this.renderer.removeClass(
            document.documentElement,
            'impersonator-mode',
          );
          this.isImpersonationMode = false;
        }
      });

    this.userIsAuthenticated = this.authService.getIsAuth();
    this.authService.autoAuthUser();
    this.authService
      .getAuthStatusListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((isAuthenticated) => {
        if (isAuthenticated) {
          this.userIsAuthenticated = true;
          this.hideNav = false;
          this.userType = this.authService.getType();
          this.notificationService.getUreadNotificationList();
          this.notificationService.getNotificationList();
          this.trialStatus = this.authService.getUserTrialStatus();

          switch (this.userType) {
            case 'school_admin':
            case 'teacher':
              this.id = this.authService.getTeacherId();
              this.teacherService
                .readSingleTeacher(this.id)
                .subscribe((res) => {
                  console.log(res);
                  const salutation = res['salutation'] ? res['salutation'] : '';
                  const firstname = res['firstname'] ? res['firstname'] : '';
                  const lastname = res['lastname'] ? res['lastname'] : '';
                  setTimeout(() => {
                    // Expert info overlay for none trial
                    if (
                      !this.trialStatus.isActive &&
                      res['report'] &&
                      res['report'].includes('expert-info') &&
                      !res['isExpert'] &&
                      !this.environment.whitelabel.disable_expert
                    ) {
                      this.openExpertGroupOverlay();
                    }
                    // Trial welcome overlay dialog
                    if (
                      this.trialStatus &&
                      res['report'] &&
                      res['report'].includes('trial-welcome')
                    ) {
                      if (
                        this.trialStatus.isActive &&
                        this.register_from !== 'no-school-swiss'
                      ) {
                        this.openTrialBanner(this.trialStatus);
                      } else {
                        this.teacherService
                          .readSingleTeacher(this.id)
                          .subscribe(() => {
                            this.removeTeacherReportInfo({
                              teacherId: this.id,
                              info: 'trial-welcome',
                            });
                          });
                      }
                    }
                    // License mapping info dialog
                    if (
                      res['report'] &&
                      res['report'].includes('license-mapping-info')
                    ) {
                      //open new license model dialog
                      this.openNewLicenseModelOverlay();
                    }
                  }, 1000);
                  if (
                    res['isExpert'] === false ||
                    res['isExpert'] === undefined
                  ) {
                    this.isExpert = false;
                  } else {
                    this.isExpert = true;
                  }

                  if (salutation === 'divers' || salutation === 'Divers') {
                    this.teacherSalutation = firstname;
                  } else {
                    this.teacherSalutation = salutation;
                  }
                  //this.teacherSalutation = salutation;
                  this.teacherLastname = lastname;
                  this.teacherInitials =
                    firstname?.slice(0, 1) + lastname?.slice(0, 1);
                  this.teacherisExpert = res['isExpert'];
                  if (res.licenseStatus) {
                    this.licenseService.setActiveLicensesUpdatedListener(
                      res.licenseStatus,
                    );
                  }
                });
              break;
            case 'student':
              this.id = this.authService.getStudentId();
              this.studentService.getStudentbyId(this.id).subscribe((res) => {
                this.student = res;
                this.starBadgeLevel = this.student?.level
                  ? String(this.student?.level)
                  : '0';
                if (this.student['bookmarks'] != undefined) {
                  this.studentService.setStudentBookmarks(
                    this.student['bookmarks'],
                  );
                }
              });
              break;
            default:
              break;
          }
          this.uuid = this.authService.getUserUuid();

          // Open trial banner after trial expiration
          // if (
          //   this.trialStatus.isActive &&
          //   this.trialStatus.expiryDate.getTime() < Date.now()
          // ) {
          //   this.openTrialBanner(this.trialStatus);
          // }
          this.authService.getCurrentTrialStatus().subscribe();
          this.authService.getTrialStatusListener().subscribe({
            next: (trialStatus) => {
              // Open trial banner
              if (
                !this.showTrialBanner && // To avoid multiple trial banners
                trialStatus.isActive &&
                this.trialStatus.expiryDate.getTime() < Date.now() &&
                this.register_from !== 'no-school-swiss'
              ) {
                this.openTrialBanner(trialStatus);
              }
            },
          });
        } else {
          this.userIsAuthenticated = false;
        }
      });

    this.licenseService
      .getActiveLicensesUpdatedListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((status) => {
        this.diagnosticLicenseStatusActive =
          status.di === 'active' ? true : false;
        this.trainingLicenseStatusActive =
          status.fo === 'active' ? true : false;
      });

    this.notificationService
      .getUnreadNotificationUpdateListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((notifications: Notification[]) => {
        console.log(notifications);
        //this.notifications = notifications;
      });

    this.notificationService
      .getNotificationUpdateListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((notifications: Notification[]) => {
        this.notifications = notifications.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
        );
      });

    this.notificationService.currentnotificationBadgeCount
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((currentnotificationBadgeCount) => {
        this.notificationBadgeCount = currentnotificationBadgeCount;
      });

    this.authService.setAuthenticatedStatus();
    // Initialize userback
    this.initUserback();

    this.breakpointObserver
      .observe(['(max-width: 767px)'])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      });
  }

  removeTeacherReportInfo(data) {
    this.teacherService
      .removeTeacherReportInfo(data)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (res) => {},
        error: (error: any) => {
          this.snackBar.openFromComponent(ErrorSnackbarComponent, {
            panelClass: 'snack-error',
            data: this.translatedText?.technical_error,
            duration: 3000,
            horizontalPosition: 'left',
          });
        },
      });
  }

  messageText(message) {
    return message.length > 110 ? message.substring(0, 110) + '...' : message;
  }

  generateStudentBadge(level) {
    if (level === undefined || level === null) {
      level = 0;
    }
    return 'streamline_filled:star-badge-' + level;
  }

  reduceSidebar(event: boolean): void {
    this.collapsedSidebar = event;
  }

  mobileOverlayHandler(event: boolean): void {
    this.mobileOverlay = event;
  }

  clickedOverlay(): void {
    this.mobileOverlay = false;
  }

  formatCreatedAt(createdAt: string): string {
    const createdAtDate = moment(createdAt);
    const today = moment();

    if (createdAtDate.isSame(today, 'day')) {
      return this.translatedText?.app_component_notification_today;
    } else if (createdAtDate.isSame(today.clone().subtract(1, 'day'), 'day')) {
      return this.translatedText?.app_component_notification_yesterday;
    } else {
      return (
        this.translatedText?.app_component_notification_vor +
        ` ${today.diff(createdAtDate, 'days')} ` +
        this.translatedText?.app_component_notification_days
      );
    }
  }

  goToHomeOrDashboard() {
    if (this.userIsAuthenticated) {
      console.log(this.userType);
      switch (this.userType) {
        case 'admin':
        case 'support':
          this.router.navigate(['/dashboard-help-desk']);
          break;
        case 'school_admin':
          this.router.navigate(['/admin-dashboard']);
          break;
        case 'teacher':
          this.router.navigate(['/dashboard-teacher']);
          break;
        case 'student':
          this.router.navigate(['/dashboard-student']);
          break;
        default:
          break;
      }
    } else {
      console.log('home');
      this.router.navigate(['/home']);
    }
  }

  /**
   * Developer helper showing hotfix version on five times title click
   */
  showHotfixVersion() {
    const currentTime = new Date().getTime();
    let timeDiff = currentTime - this.hotfixClickDate;
    //console.log(timeDiff)
    if (timeDiff < 1000) {
      this.hotfixCount++;
    } else {
      this.hotfixCount = 0;
      this.hideHotfixVersion = true;
    }
    if (this.hotfixCount > 4) {
      this.hideHotfixVersion = false;
    }
    this.hotfixClickDate = currentTime;
  }

  isTeacherView() {
    return this.userType !== 'student' ? true : false;
  }

  downloadDocument(filename) {
    window.location.href = this.externalAssetsUrl + '/assets/docs/' + filename;
  }

  logout() {
    this.authService.logout();
    this.userIsAuthenticated = false;
  }

  leaveImpersonationMode() {
    this.authService.impersonationLogout();
  }

  hoverSection($event) {
    this.hoverClass =
      $event.type === 'mouseover'
        ? 'register-section--active'
        : 'register-section';
  }

  handleMobileMenu(): void {
    this.clickedMobileMenu = !this.clickedMobileMenu;
  }

  openLoginDialog(): void {
    if (this.clickedMobileMenu) {
      this.clickedMobileMenu = false;
    }
    let dialogRef = this.dialog.open(LoginDialogComponent, {
      width: '85vw',
      autoFocus: false,
      disableClose: true,
      panelClass: 'home-dialog-panel',
      // hasBackdrop: false,
      backdropClass: 'license-add-backdrop',
    });
    dialogRef.afterClosed().subscribe((res) => {});
  }
  openSubscribeDialog(): void {
    if (this.environment.whitelabel.register_no_dialog) {
      this.router.navigate(['/registrierung']);
      return;
    }
    if (this.clickedMobileMenu) {
      this.clickedMobileMenu = false;
    }
    let dialogRef = this.dialog.open(SubscribeDialogComponent, {
      width: '90%',
      autoFocus: false,
      disableClose: true,
      panelClass: 'home-dialog-panel',
      // hasBackdrop: false,
      backdropClass: 'license-add-backdrop',
    });
    dialogRef.afterClosed().subscribe((res) => {});
  }

  openNotificationList(notification): void {
    let dialogRef = this.dialog.open(NotificationListComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'notification-list-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'books-preferences-overlay-panel_backdrop',
      data: {
        notifications: [notification],
        task: notification['_task'],
        view: 'list',
      },
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((res) => {});
  }

  openBookPreferences(): void {
    let dialogRef = this.dialog.open(BookPreferencesComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'books-preferences-overlay-panel',
      // hasBackdrop: false,
      disableClose: false,
      backdropClass: 'books-preferences-overlay-panel_backdrop',
      data: {
        step: 'selection',
      },
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((res) => {});
  }

  openMicrophoneCheck(): void {
    let dialogRef = this.dialog.open(MicrophoneCheckComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'microphone-check-overlay-panel',
      // hasBackdrop: false,
      disableClose: false,
      backdropClass: 'microphone-check-overlay-panel_backdrop',
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((res) => {});
  }

  openPinCode(userId): void {
    let dialogRef = this.dialog.open(PinCodeComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'pin-code-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'pin-code-backdrop',
      data: {
        overlayView: 'teacherView',
      },
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((res) => {
      if (res?.data?.length > 0) {
        this.authService.impersonateUser(userId, res.data);
      }
    });
  }

  impersonateUser(userId) {
    this.openPinCode(userId);
  }

  openChangePassword(): void {
    let dialogRef = this.dialog.open(ChangePasswordComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'change-password-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'change-password-backdrop',
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((res) => {
      if (res === 'forgot-password') {
        this.colClass = 'col-md-12';
      }
    });
  }

  removeExpertStatus(): void {
    this.teacherService
      .updateTeacher({
        teacherId: this.id,
        isExpert: false,
      })
      .subscribe({
        next: (res) => {
          // Update teacher expert status
          this.teacherisExpert = false;
        },
        error: (error: any) => {
          this.snackBar.openFromComponent(ErrorSnackbarComponent, {
            panelClass: 'snack-error',
            data: this.translatedText?.technical_error,
            duration: 3000,
            horizontalPosition: 'left',
          });
        },
      });
  }

  // openEditTeacher(): void {
  //   let dialogRef = this.dialog.open(TeacherEditComponent, {
  //     width: '100%',
  //     autoFocus: false,
  //     panelClass: 'change-password-panel',
  //     // hasBackdrop: false,
  //     disableClose: true,
  //     backdropClass: 'change-password-backdrop',
  //   });
  //   // dialogRef.disableClose = true;
  //   dialogRef.afterClosed().subscribe((res) => {
  //     this.id = this.authService.getTeacherId();
  //     this.teacherService.readSingleTeacher(this.id).subscribe((res) => {
  //       console.log(res);
  //       const salutation = res['salutation'] ? res['salutation'] : '';
  //       const firstname = res['firstname'] ? res['firstname'] : '';
  //       const lastname = res['lastname'] ? res['lastname'] : '';
  //       if (res['isExpert'] === false || res['isExpert'] === undefined) {
  //         this.isExpert = false;
  //       } else {
  //         this.isExpert = true;
  //       }
  //       if (salutation === 'divers' || salutation === 'Divers') {
  //         this.teacherSalutation = firstname;
  //       } else {
  //         this.teacherSalutation = salutation;
  //       }
  //       this.teacherLastname = lastname;
  //       this.teacherInitials = firstname?.slice(0, 1) + lastname?.slice(0, 1);
  //       if (res.licenseStatus) {
  //         this.licenseService.setActiveLicensesUpdatedListener(
  //           res.licenseStatus,
  //         );
  //       }
  //     });
  //   });
  // }

  alert(text) {
    alert(text);
  }

  openReadingLevel(level, status): void {
    let dialogRef = this.dialog.open(ReadingLevelOverlayComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'reading-level-overlay-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'reading-level-overlay-panel_backdrop',
      data: {
        readingLevel: level,
        readingLevelUpgrade: status === 'up' ? true : false,
      },
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((res) => {
      this.updateStudent();
    });
  }

  // get latest levelHistory object from array
  findLatestLevelProgress(levelHistory) {
    if (levelHistory && levelHistory.length > 0) {
      levelHistory.sort(
        (a, b) => new Date(b.created).getTime() - new Date(a.created).getTime(),
      );
      const latestEntry = levelHistory[0];
      return {
        level: latestEntry.level,
        progress: latestEntry.progress,
      };
    }
    return null;
  }

  updateStudent() {
    let formData = {
      _id: this.id,
      report: this.studentReportValue,
    };
    console.log(formData);
    this.studentService
      .updateStudentPreferences(formData)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res) => {
          // set new level for header
          this.student.level = res['student'].level;
          this.starBadgeLevel = res['student'].level;
        },
        (error: any) => {},
      );
  }

  selectLanguage(language) {
    this.translationService.setLanguage(language);

    if (this.langAlertOnce === 0) {
      let dialogRef = this.dialog.open(LanguageChangeAlertComponent, {
        width: '100%',
        autoFocus: false,
        panelClass: 'license-add-panel',
        // hasBackdrop: false,
        disableClose: true,
        backdropClass: 'license-add-backdrop',
      });
      // dialogRef.disableClose = true;
      dialogRef
        .afterClosed()
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res) => {});
    }
    this.langAlertOnce++;
  }

  getCurrentLanguage() {
    return this.getFrontendLanguageCode(
      this.translationService.getCurrentLanguage(),
    );
  }

  getFrontendLanguageCode(lang: string): string {
    const parts = lang.split('_');
    return parts.length > 1 ? parts[1] : parts[0];
  }

  openUrl(url: string) {
    const httpsRegex = /^https:\/\//;

    // Check if the URL starts with https
    if (httpsRegex.test(url)) {
      // If URL is https, open the URL in a new tab
      window.open(url, '_blank');
    } else {
      // If not https, navigate using the router
      this.router.navigate([url]);
    }
  }

  openTrialBanner(trialStatus): void {
    this.showTrialBanner = true;
    let dialogRef = this.dialog.open(TrialBannersComponent, {
      width: '100%',
      autoFocus: false,
      disableClose: true,
      panelClass: 'trial-banner-panel',
      backdropClass: 'license-transfer-backdrop',
      data: {
        trialStatus: trialStatus,
        source: 'trial',
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      this.teacherService.readSingleTeacher(this.id).subscribe(() => {
        if (res == 'trial-welcome') {
          this.removeTeacherReportInfo({
            teacherId: this.id,
            info: 'trial-welcome',
          });
        }
      });
    });
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    let getScreenWidth = window.innerWidth;
    let getScreenHeight = window.innerHeight;
    if (
      (getScreenWidth < 1000 || getScreenHeight < 615) &&
      this.devicePlatform === 'web'
    ) {
      this.dialog.open(ZoomWarningComponent, {
        width: '100%',
        autoFocus: false,
        disableClose: true,
        panelClass: 'zoom-warning-panel',
        hasBackdrop: false,
      });
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
